import React, { useEffect, useState } from "react";
import "./AddPost.css";
import { getCategoryBodyPartApi, getPostsApi } from "../components/APIs/Api";
import ImagePicker from "../components/ImagePicker";
import HtmlEditor from "../pages/HtmlEditor";
import toast from "react-hot-toast";
import { addPostApi, EditPostApi } from "../components/APIs/Api";
import { Spinner } from "react-bootstrap";
import AWS from "aws-sdk";
import S3Image from "../components/S3Image";
import MultiSelectDropdown from "../components/MultiSelectDropdown";

const AddPost = (props) => {
  const s3 = new AWS.S3();
  let errorsObj = {
    name: "",
    categoryOption: "",
    bodyPartOption: "",
    difficultOption: "",
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [name, setName] = useState("");
  const [instructions, setInstruction] = useState("");
  const [categoryOption, setCategoryOption] = useState("");
  const [bodyPartOption, setBodyPartOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [difficultOption, setDifficultOption] = useState("");
  const [showThumbnail, setShowThumbnail] = useState("");
  const [show, setShow] = useState(0);
  const [mistakesInputs, setMistakesInputs] = useState([""]);
  const [benefitsInputs, setBenefitsInputs] = useState([""]);
  const [linkedExercises, setLinkedExercises] = useState([]);
  const [time, srtTime] = useState();
  const [errors, setErrors] = useState(errorsObj);
  const [categoryDropdownsList, setCategoryDropdownsList] = useState([]);
  const [bodyPartDropdownsList, setBodyPartDropdownsList] = useState([]);
  const [exercisesOptions, setExercisesOptions] = useState([]);

  console.log(exercisesOptions);

  const options = exercisesOptions.map((exercise) => ({
    value: exercise._id,
    label: exercise.name,
  }));
  const alternatives = linkedExercises.map((exercise) => exercise.value);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  async function getDropdownList(type) {
    setLoader(true);
    try {
      const response = await getCategoryBodyPartApi(type);
      if (type === "equipment") {
        setCategoryDropdownsList(response?.data?.data);
      } else if (type === "bodyPart") {
        setBodyPartDropdownsList(response?.data?.data);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }
  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleCreateNewInput = (index, value) => {
    const newInputs = [...mistakesInputs];
    newInputs[index] = value;
    setMistakesInputs(newInputs);
  };
  const handleCreateBenefitsInput = (index, value) => {
    const newInputs = [...benefitsInputs];
    newInputs[index] = value;
    setBenefitsInputs(newInputs);
  };

  const handleAddInput = () => {
    setMistakesInputs([...mistakesInputs, ""]);
  };
  const handleAddBenefitsInput = () => {
    setBenefitsInputs([...benefitsInputs, ""]);
  };
  async function getExercisesList() {
    setLoader(true);
    try {
      const response = await getPostsApi("active");
      setExercisesOptions(
        response?.data?.data?.exercises.map((exercise) => ({
          value: exercise._id,
          label: exercise.name,
        }))
      );
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }
  //===============================Remove Inputs =============================
  const handleRemoveBenefitsInput = (indexToRemove) => {
    setBenefitsInputs(
      benefitsInputs.filter((input, index) => index !== indexToRemove)
    );
  };
  const handleRemoveMistakesInput = (indexToRemove) => {
    setMistakesInputs(
      mistakesInputs.filter((input, index) => index !== indexToRemove)
    );
  };

  function handleOnNext() {
    let error = false;
    const errorObj = { ...errorsObj };
    if (name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (categoryOption.trim() === "") {
      errorObj.categoryOption = "This field is required";
      error = true;
    }
    if (bodyPartOption.trim() === "") {
      errorObj.bodyPartOption = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      setShow(1);
      scrollToTop();
    }
  }
  const handleRemoveImage = () => {
    setImage(null);
    setShowThumbnail("");
    setSelectedImage("");
    setThumbnail("");
  };

  async function handleAddPost() {
    let error = false;
    const errorObj = { ...errorsObj };
    if (difficultOption.trim() === "") {
      errorObj.difficultOption = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoader(true);
    if (props?.location?.state?.name?.length > 0) {
      try {
        let thumbnailImage;
        const imageData = await handleUpload(image);
        if (thumbnail) {
          thumbnailImage = await handleUpload(thumbnail);
        }

        const postData = {
          media: imageData?.Key,
          name: name,
          equipment: categoryOption,
          bodyPart: bodyPartOption,
          instructions: instructions,
          time: time,
          difficulty: difficultOption,
          commonMistakes: mistakesInputs,
          benefits: benefitsInputs,
          alternatives: alternatives,
          exerciseId: props?.location?.state?._id,
        };
        if (thumbnail) {
          postData.thumbnail = thumbnailImage.Key;
        }
        const response = await EditPostApi(postData);
        toast.success("Edit Post Successfully");
        props.history.push("posts");
      } catch (e) {
        toast.error(e?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    } else {
      try {
        let thumbnailImage;
        const imageData = await handleUpload(image);
        if (thumbnail) {
          thumbnailImage = await handleUpload(thumbnail);
        }
        const postData = {
          media: imageData?.Key,
          name: name,
          equipment: categoryOption,
          bodyPart: bodyPartOption,
          instructions: instructions,
          time: time,
          difficulty: difficultOption,
          commonMistakes: mistakesInputs,
          benefits: benefitsInputs,
          alternatives: alternatives,
        };
        if (thumbnail) {
          postData.thumbnail = thumbnailImage.Key;
        }
        const response = await addPostApi(postData);
        toast.success("Added Post Successfully");
        props.history.push("posts");
      } catch (e) {
        toast.error(e?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    }
  }

  useEffect(() => {
    getDropdownList("equipment");
    getDropdownList("bodyPart");
    getExercisesList();
  }, []);

  useEffect(() => {
    if (props?.location?.state) {
      const { state } = props.location;
      setSelectedImage(state.media || null);
      setName(state.name || "");
      setShowThumbnail(state.thumbnail || "");
      setInstruction(state.instructions || "");
      setCategoryOption(state?.category?._id || "");
      setBodyPartOption(state?.bodyPart?._id || "");
      srtTime(state.time || "");
      setDifficultOption(state.difficulty || "");
      setMistakesInputs(state.commonMistakes || [""]);
      setBenefitsInputs(state.benefits || [""]);
      setCategoryOption(state?.equipment?._id || "");
      setLinkedExercises(
        state?.alternatives.map((exercise) => ({
          value: exercise._id,
          label: exercise.name,
        })) || []
      );
    }
  }, [props?.location?.state]);

  return (
    <div className="p-3">
      <h3 className="">{props?.location?.state ? "Edit Post" : "Add Post"}</h3>
      {show === 0 ? (
        <div>
          <div className="form-container">
            <div className="left-side">
              {selectedImage?.length > 0 ? (
                <S3Image
                  type={"edit-img"}
                  fileType={props?.location?.state ? "image" : "mp4"}
                  imageKey={
                    props?.location?.state?.thumbnail
                      ? showThumbnail
                      : props?.location?.state?.media
                  }
                  onRemove={handleRemoveImage}
                />
              ) : (
                <ImagePicker
                  selectedImage={image}
                  setSelectedImage={setImage}
                  setThumbnail={setThumbnail}
                  thumbnail={thumbnail}
                  onRemove={handleRemoveImage}
                />
              )}
            </div>
            <div className="right-side">
              <label>Exercise Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your exercise name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    name: "",
                  }));
                }}
              />
              {errors.name && (
                <div className="text-danger fs-12">{errors.name}</div>
              )}
              <label>Equipment</label>
              <select
                className="form-control"
                value={categoryOption}
                onChange={(e) => {
                  setCategoryOption(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    categoryOption: "",
                  }));
                }}
              >
                <option value="">Select...</option>
                {categoryDropdownsList.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.title}
                  </option>
                ))}
              </select>
              {errors.categoryOption && (
                <div className="text-danger fs-12">{errors.categoryOption}</div>
              )}
              <label>Body Part</label>
              <select
                className="form-control"
                value={bodyPartOption}
                onChange={(e) => {
                  setBodyPartOption(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    bodyPartOption: "",
                  }));
                }}
              >
                <option value="">Select...</option>
                {bodyPartDropdownsList.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.title}
                  </option>
                ))}
              </select>
              {errors.bodyPartOption && (
                <div className="text-danger fs-12">{errors.bodyPartOption}</div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end ">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleOnNext()}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div className="pt-2">
          <div className="pb-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setShow(0)}
            >
              prev
            </button>
          </div>
          <label>Instructions</label>
          <HtmlEditor setValue={setInstruction} showValue={instructions} />
          <div className="second-section pt-3">
            <div>
              <label>Time</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Time"
                value={time}
                onChange={(e) => srtTime(e.target.value)}
                min={1}
              />
            </div>
            <div>
              <label>Difficulty Level</label>
              <select
                className="form-control"
                value={difficultOption}
                onChange={(e) => {
                  setDifficultOption(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    difficultOption: "",
                  }));
                }}
              >
                <option value="">Select...</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advance">Advance</option>
              </select>
              {errors.difficultOption && (
                <div className="text-danger fs-12">
                  {errors.difficultOption}
                </div>
              )}
            </div>
          </div>
          <div className="second-section">
            <div className="">
              <label>Benefits</label>
              {benefitsInputs.map((value, index) => (
                <div
                  key={index}
                  className=" d-flex flex-column mb-3"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    required
                    placeholder="Enter Benefits"
                    className="form-control"
                    value={benefitsInputs[index]}
                    onChange={(e) =>
                      handleCreateBenefitsInput(index, e.target.value)
                    }
                  />
                  {index !== 0 && (
                    <div>
                      <button
                        className="remove-btn"
                        style={{ height: "30px", width: "30px" }}
                        onClick={() => handleRemoveBenefitsInput(index)}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddBenefitsInput}
                  type="button"
                >
                  Add
                </button>
              </div>
            </div>
            <div>
              <label>Common Mistakes to Avoid</label>
              {mistakesInputs.map((value, index) => (
                <div
                  key={index}
                  className=" d-flex flex-column mb-3 position-relative"
                >
                  <input
                    type="text"
                    required
                    placeholder="Enter Common Mistakes"
                    className="form-control"
                    value={mistakesInputs[index]}
                    onChange={(e) =>
                      handleCreateNewInput(index, e.target.value)
                    }
                  />
                  {index !== 0 && (
                    <div>
                      <button
                        className="remove-btn"
                        style={{ height: "30px", width: "30px" }}
                        onClick={() => handleRemoveMistakesInput(index)}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddInput}
                  type="button"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div>
            <label>Linked Exercise</label>

            <MultiSelectDropdown
              selected={linkedExercises}
              setSelected={setLinkedExercises}
              options={exercisesOptions}
            />
          </div>
          <div className="d-flex justify-content-end pt-3">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleAddPost()}
            >
              {loader ? <Spinner animation="border" size="sm" /> : "Save"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPost;
