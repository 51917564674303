import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
// import PageTitle from "../components/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import Switch from "react-switch";
import toast, { Toaster } from "react-hot-toast";
import { blockUserApi } from "..//components/APIs/Api";
import PageTitle from "../layouts/PageTitle";
import defaultImg from "../../images/defaulImg.jpg";
import Pagination from "../components/Pagination";
import { deleteUserApi } from "..//components/APIs/Api";
import FilterSearch from "../components/FilterSearch";
import UserDetails from "./UserDetail";
import EditUserModal from "../modal/EditUserModal";
import S3Image from "../components/S3Image";
export default function UserManagement(props) {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [search, setSearch] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };
  const limitOptions = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "40", label: "40" },
    { value: "60", label: "60" },
    { value: "80 ", label: "80 " },
    { value: "100", label: "100" },
  ];

  async function userListData() {
    setLoader(true);
    try {
      const res = await userManagementList(itemsPerPage, currentPage, search);
      setUsersList(res);
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function hendleDeleteUser(id) {
    try {
      const res = await deleteUserApi(id);
      userListData();
      setLoader(false);
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      setLoader(false);
      throw new Error(error);
    }
  }

  function deleteUserPromise(id) {
    toast.promise(hendleDeleteUser(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  async function blockUser(id) {
    setLoader(true);
    try {
      const res = await blockUserApi(id);
      userListData();

      if (res?.data?.data === false) {
        toast.success("User Active");
      } else {
        toast.error("User Blocked");
      }
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  const totalItems = usersList?.data?.data.count;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    userListData();
    scrollToTop();
  }, [itemsPerPage, currentPage, search]);

  return (
    <>
      <div>
        <PageTitle
          activeMenu="Users List"
          motherMenu="Users"
          isButton={false}
        />
        <Col className="pr-0">
          {loader ? (
            <div>
              <Lottie
                style={{
                  height: "200px",
                  marginTop: "45px",
                  padding: "30px",
                }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black", textAlign: "center" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>

                      <th>
                        <strong>STATUS</strong>
                      </th>

                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {usersList?.data?.data?.users?.map((item, i) => (
                      <tr
                        key={item.id}
                        className={i % 2 === 0 ? "even-row" : ""}
                      >
                        <td>
                          <S3Image
                            imageKey={item?.image}
                            type={"Profiles"}
                            fileType={"profile-image"}
                            style={"profile-photo"}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>

                        <td>
                          <label className="m-0">
                            <Switch
                              onChange={() => blockUser(item._id)}
                              checked={!item?.isBlocked}
                            />
                          </label>
                        </td>
                        <td className="">
                          <div
                            className="btn btn-primary shadow btn-xs sharp mr-2 view-list-icon"
                            onClick={() => {
                              setDetailsModal(true);
                              setSelectedUser(item);
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </div>
                          <div
                            class="btn btn-warning shadow btn-xs sharp acton-icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditUserModal(true);
                              setSelectedUser(item);
                            }}
                          >
                            <i class="fa fa-pencil"></i>
                          </div>
                          <div
                            class="btn btn-danger shadow btn-xs sharp acton-icons ml-2"
                            onClick={() => deleteUserPromise(item?._id)}
                            style={{ cursor: "pointer" }}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {usersList?.data?.data?.count === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.count !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <FilterSearch
                          FilterOption={limitOptions}
                          setFilterType={setItemsPerPage}
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {detailsModal && (
        <UserDetails
          show={detailsModal}
          onHide={() => setDetailsModal(false)}
          selectedUserData={selectedUser}
        />
      )}
      {editUserModal && (
        <EditUserModal
          show={editUserModal}
          onHide={() => setEditUserModal(false)}
          selectedUserData={selectedUser}
        />
      )}
    </>
  );
}
