import React, { useEffect, useState } from "react";
import { Card, Tab, Nav, Dropdown, Spinner } from "react-bootstrap";
import { getPostsApi } from "../components/APIs/Api";
import PageTitle from "../layouts/PageTitle";
import ReelCard from "./ReelCard";
import AddPost from "../modal/AddPost";
import toast from "react-hot-toast";

export default function Post() {
  const [selectedTab, setSelectedTab] = useState("draft");
  const [addPostModal, setAddPostModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const tabData = [
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },

    {
      name: "Deleted",
      value: "deleted",
    },
  ];

  async function getExercisesList() {
    setLoader(true);

    try {
      const response = await getPostsApi(selectedTab);
      setData(response.data.data.exercises);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getExercisesList();
  }, [selectedTab]);

  return (
    <div>
      <PageTitle
        activeMenu="Posts"
        motherMenu="List"
        isButton={false}
        action2={"add-post"}
      />

      <div className="col-xl-12 pr-0">
        <Card className="post-card">
          <Card.Body>
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map(
                  (data, i) =>
                    i !== tabData.length && (
                      <Nav.Item
                        as="li"
                        key={i}
                        onClick={() => setSelectedTab(data.value)}
                      >
                        <Nav.Link
                          eventKey={data.name.toLowerCase()}
                          className="d-flex align-items-center tab-section"
                        >
                          <li className="m-0 pr-3">{data.name}</li>
                        </Nav.Link>
                      </Nav.Item>
                    )
                )}
              </Nav>
              <Tab.Content className="pt-4">
                {selectedTab === "active" && (
                  <div
                    className="d-flex flex-wrap justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        {data && data.length > 0 ? (
                          data.map((item, i) => (
                            <ReelCard
                              key={i}
                              index={i}
                              item={item}
                              refresh={getExercisesList}
                              setLoader={setLoader}
                            />
                          ))
                        ) : (
                          <p>Sorry, no data found</p>
                        )}
                      </>
                    )}
                  </div>
                )}
                {selectedTab === "inactive" && (
                  <div
                    className="d-flex flex-wrap justify-content-start"
                    style={{ gap: "10px" }}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        {data && data.length > 0 ? (
                          data.map((item, i) => (
                            <ReelCard
                              key={i}
                              index={i}
                              item={item}
                              refresh={getExercisesList}
                              setLoader={setLoader}
                            />
                          ))
                        ) : (
                          <p>Sorry, no data found</p>
                        )}
                      </>
                    )}
                  </div>
                )}
                {selectedTab === "deleted" && (
                  <div
                    className="d-flex flex-wrap justify-content-start"
                    style={{ gap: "10px" }}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        {data && data.length > 0 ? (
                          data.map((item, i) => (
                            <ReelCard
                              key={i}
                              index={i}
                              item={item}
                              refresh={getExercisesList}
                              setLoader={setLoader}
                            />
                          ))
                        ) : (
                          <p>Sorry, no data found</p>
                        )}
                      </>
                    )}
                  </div>
                )}
                {selectedTab === "draft" && (
                  <div
                    className="d-flex flex-wrap justify-content-start"
                    style={{ gap: "10px" }}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        {data && data.length > 0 ? (
                          data.map((item, i) => (
                            <ReelCard
                              key={i}
                              index={i}
                              item={item}
                              refresh={getExercisesList}
                              setLoader={setLoader}
                            />
                          ))
                        ) : (
                          <p>Sorry, no data found</p>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </div>
      {addPostModal && (
        <AddPost show={addPostModal} hide={() => setAddPostModal(false)} />
      )}
    </div>
  );
}
