import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Dropdown, Card, Table, Badge, Col, Spinner } from "react-bootstrap";
import ListIcon from "../../../images/list.png";
import PageTitle from "../../layouts/PageTitle";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../../images/loding.json";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  appConfigApi,
  addConfigApi,
  deleteAppConfigApi,
  subComponentToggleApi,
} from "../../components/APIs/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import AddConfig from "../../modal/AddConfig";
import FilterSearch from "../../components/FilterSearch";
import { configFilterLabels } from "../../components/Variable";

export default function AppConfig(props) {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addConfigModal, setAddConfigModal] = useState(false);
  const [filterTab, setFilterTab] = useState("active");

  async function configListData() {
    setLoader(true);
    try {
      const res = await appConfigApi();
      setData(res);
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function handleDeleteComponent(id) {
    setLoader(true);
    try {
      const res = await deleteAppConfigApi(id);
      configListData();
      setLoader(false);
      toast.success("Component deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  }

  useEffect(() => {
    configListData();
  }, []);

  return (
    <div>
      <PageTitle
        activeMenu="App Config"
        motherMenu="List"
        isButton={true}
        action={setAddConfigModal}
      />

      <Col className="pr-0">
        {/* <div className="mt-4 d-flex justify-content-between align-items-center">
            <div
              className="form-group "
              style={{ position: "relative", width: "40%" }}
            >
              <input
                type="text"
                style={{ borderRadius: "0.5rem" }}
                className="form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div> */}
        {/* <div className="pb-2 d-flex justify-content-start">
          <FilterSearch
            Type="off"
            tabs={true}
            FilterOption={configFilterLabels}
            setFilterType={setFilterTab}
            filterType={filterTab}
          />
        </div> */}
        {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table>
                <thead style={{ color: "black", textAlign: "center" }}>
                  <tr>
                    <th>
                      <strong>S.NO</strong>
                    </th>
                    <th>
                      <strong>Component</strong>
                    </th>
                    <th>
                      <strong>Updated On</strong>
                    </th>
                    <th>
                      <strong>Value</strong>
                    </th>

                    <th>
                      <strong>View List</strong>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {data?.data?.data?.components?.map((item, i) => (
                    <tr
                      key={item._id}
                      className={i % 2 === 0 ? "even-row" : ""}
                    >
                      <td>{i + 1}</td>
                      <td>
                        {item?.title?.length > 30
                          ? item?.title?.slice(0, 25) + ".."
                          : item?.title}
                      </td>
                      <td>{moment(item.updatedAt).format("LL")}</td>
                      <td>{item?.subComponents?.length}</td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          {/* {!item?.isDeleted && (
                            <div className="btn btn-warning shadow btn-xs sharp mr-1 view-list-icon">
                              {filterTab === "active" ? (
                                <div
                                // onClick={() =>
                                //   handleActionWorkout(program?._id)
                                // }
                                >
                                  <FontAwesomeIcon
                                    icon={faToggleOn}
                                    className="icon"
                                  />
                                </div>
                              ) : (
                                <div
                                // onClick={() =>
                                //   handleActionWorkout(program?._id)
                                // }
                                >
                                  <FontAwesomeIcon
                                    icon={faToggleOff}
                                    className="icon"
                                  />
                                </div>
                              )}
                            </div>
                          )} */}
                          <div
                            className="btn btn-danger shadow btn-xs sharp view-list-icon"
                            onClick={() => handleDeleteComponent(item?._id)}
                          >
                            <i className="fa fa-trash"></i>
                          </div>
                          <div
                            className="ml-1"
                            onClick={() =>
                              props.history.push({
                                pathname: "category-config",
                                state: { item: item },
                              })
                            }
                          >
                            <img
                              src={ListIcon}
                              alt="icon"
                              className="view-list-icon"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {data?.data?.data?.components?.length === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {/* {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <FilterSearch
                          FilterOption={limitOptions}
                          setFilterType={setItemsPerPage}
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
            </Card.Body>
          </Card>
        )}
      </Col>
      {addConfigModal && (
        <AddConfig
          show={addConfigModal}
          onHide={() => setAddConfigModal(false)}
          title={"Add Component"}
          buttonTitle={"Add"}
          api={addConfigApi}
          refresh={configListData}
        />
      )}
    </div>
  );
}
