import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import AddExercisesModal from "../modal/AddExercisesModal";
import {
  addProgramWorkoutApi,
  editProgramWorkoutApi,
  programWorkoutApi,
} from "../components/APIs/Api";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import AddProgramWorkoutModal from "../modal/AddProgramWorkout";
import moment from "moment";

export default function ProgramWorkoutList(props) {
  const workoutItem = props.location.state;
  const [addWorkout, setAddWorkout] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState();
  const [tableData, setTableData] = useState([]);
  const [editWorkout, setEditWorkout] = useState(false);

  async function handleTableList() {
    setLoader(true);
    try {
      const res = await programWorkoutApi(workoutItem?.workout?._id);
      setTableData(res?.data?.data);
      console.log(res);
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleTableList();
  }, []);

  return (
    <div>
      <PageTitle
        activeMenu="Workout List"
        motherMenu={workoutItem?.workout?.title}
        isButton={true}
        action={setAddWorkout}
      />

      <Col>
        {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Workout NAME</strong>
                    </th>
                    <th>
                      <strong>Day</strong>
                    </th>
                    <th>
                      <strong>Updated on</strong>
                    </th>
                    <th>
                      <strong>Exercise count</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((workout) => (
                    <tr key={workout._id}>
                      <td>
                        <span className="w-space-no">
                          {workout?.workout?.title}
                        </span>
                      </td>
                      <td>
                        <span className="w-space-no">{workout?.title}</span>
                      </td>
                      <td>
                        {moment(workout?.workout?.updatedAt).format("LL")}
                      </td>
                      <td>{workout?.workout?.routine?.length}</td>
                      <td>
                        <div className="d-flex">
                          <div
                            className="btn btn-primary shadow btn-xs sharp ml-3 view-list-icon"
                            onClick={() => {
                              setEditWorkout(true);
                              setSelectedItemData(workout);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {tableData?.length === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {addWorkout && (
        <AddProgramWorkoutModal
          title={"Add New Workout"}
          show={addWorkout}
          onHide={() => setAddWorkout(false)}
          buttonTitle={"Add"}
          api={addProgramWorkoutApi}
          refresh={handleTableList}
          componentId={workoutItem?.workout?._id}
          successMessage={"Added Workout successfully"}
          inputName={"Workout name"}
        />
      )}
      {editWorkout && (
        <AddProgramWorkoutModal
          title={"Edit Program Workout"}
          show={editWorkout}
          onHide={() => setEditWorkout(false)}
          buttonTitle={"Save"}
          api={editProgramWorkoutApi}
          refresh={handleTableList}
          componentId={workoutItem?.workout?._id}
          selectedItemData={selectedItemData}
          successMessage={"Edit Workout successfully"}
          inputName={"Workout name"}
        />
      )}
    </div>
  );
}
