import React, { useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import AWS from "aws-sdk";

import toast from "react-hot-toast";

export default function AddNameModal({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  componentId,
  inputName,
}) {
  const s3 = new AWS.S3();

  const [name, setName] = useState();
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState([]);

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (name.trim().length === 0) {
      setNameError("Name is required");
      return;
    }
    setLoader(true);

    try {
      let programImage;
      if (image) {
        programImage = await handleUpload(image);
      }
      const postData = {
        name,
        image: programImage.Key,
      };
      const res = await api(postData);
      setLoader(false);
      refresh();
      onHide(false);
      toast.success("Added Successfully");
    } catch (e) {
      setLoader(false);
      console.log(e?.response);
      toast.error(e?.response?.data?.message);
    }
  }

  return (
    <Modal className="sortBy" show={show} onHide={onHide} centered>
      <div className="d-flex justify-content-between  align-items-center p-3">
        <h3 className="text-center">{title}</h3>
        <button type="button" className="close" onClick={() => onHide()}>
          <span>×</span>
        </button>
      </div>

      <form onSubmit={handleAddFormSubmit}>
        <div className="form-group p-3">
          <>
            <label className="text-black font-w500">{"Image"}</label>
            <div className="contact-name">
              <input
                type="file"
                className="form-control"
                required="required"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
              />
            </div>
          </>
          <label className="text-black font-w500">{inputName}</label>
          <div className="contact-name ">
            <input
              type="text"
              className="form-control"
              name="Date_Join"
              required="required"
              onChange={(e) => {
                setName(e.target.value);
                setNameError();
              }}
              placeholder="Enter Name"
            />
            <span className="text-danger">{nameError}</span>
          </div>
        </div>

        <div className="p-3 d-flex justify-content-end">
          <button className="btn btn-sm btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
}
