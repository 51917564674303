import React from "react";
import Nav from "react-bootstrap/Nav";

export default function FilterSearch({
  // setSearch,
  // setStartDate,
  // setEndDate,
  FilterOption,
  setFilterType,
  Type,
  limitValue,
  title,
  filterType,
  tabs,
}) {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{ flexWrap: "wrap" }}
      >
        {tabs && (
          <div className="filter-tabs">
            {FilterOption.map((option, index) => (
              <p
                onClick={() => setFilterType(option.value)}
                className={`${
                  option.value === filterType ? "active-tab" : "tab"
                }`}
              >
                {option.label}
              </p>
            ))}
          </div>
        )}
        {Type === "off" ? (
          <div></div>
        ) : (
          <div>
            <select
              style={{
                textAlign: "start",

                maxHeight: "38px",
              }}
              class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm form-control"
              onChange={(e) => setFilterType(e.target.value)}
              value={limitValue}
            >
              {FilterOption?.map((item) => (
                <option className="form-control" value={item?.value} key={item}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </>
  );
}
