import React from "react";
import "./UserDetails.css"; // Import the CSS file
import { Badge, Modal } from "react-bootstrap";
import moment from "moment";
import S3Image from "../components/S3Image";

const UserDetails = ({ show, onHide, selectedUserData }) => {
  return (
    <Modal show={show} onHide={onHide} centered className="user-details-modal">
      <div className="profile-card">
        <div className="image-header">
          {/* <img
            src="https://via.placeholder.com/150"
            alt="Profile"
            className="profile-image"
          /> */}
          <S3Image
            imageKey={selectedUserData?.image}
            type={"Profiles"}
            style="profile-image"
          />
        </div>
        <div className="content">
          <span>
            {selectedUserData?.name?.length > 20
              ? selectedUserData?.name?.slice(0, 20) + "..."
              : selectedUserData?.name}
          </span>
          <p className="m-0">{selectedUserData?.phoneNumber}</p>
          <p>
            {selectedUserData?.email?.length > 30
              ? selectedUserData?.email?.slice(0, 30) + "..."
              : selectedUserData?.email}
          </p>
          <div className="social-links"></div>
          {/* <div className="actions">
            <h4 className="subscribe-button">Status</h4>
            {selectedUserData?.isBlocked ? (
              <Badge variant="danger light">Inactive</Badge>
            ) : (
              <Badge variant="success light">Active</Badge>
            )}
          </div> */}
          <div className="stats">
            <span>CreatedAt</span>
            <span>{moment(selectedUserData?.createdAt).format("LL")}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetails;
