import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../layouts/PageTitle";
import FilterSearch from "../components/FilterSearch";
import { workoutFilterLabels } from "../components/Variable";
import AddNameModal from "../modal/AddNameModal";
import {
  workoutManagementListApi,
  addWorkoutApi,
  activeInactiveApi,
} from "../components/APIs/Api";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import moment from "moment";
import S3Image from "../components/S3Image";

export default function WorkoutManagement(props) {
  const [filterTab, setFilterTab] = useState("draft");
  const [selectedActionItems, setSelectedActionItems] = useState([]);
  const [addWorkout, setAddWorkout] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState();

  async function handleTableList() {
    setLoader(true);
    try {
      const res = await workoutManagementListApi(filterTab);
      setTableData(res?.data?.data);
      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleTableList();
    setSelectedActionItems([]);
  }, [filterTab]);

  const handleCheckboxChange = (id) => {
    setSelectedActionItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedActionItems.length === tableData.count) {
      setSelectedActionItems([]);
    } else {
      setSelectedActionItems(tableData?.workouts?.map((item) => item._id));
    }
  };

  async function handleActionWorkout(id) {
    setLoader(true);

    try {
      const postData = {
        id,
        type: "workout",
      };
      const res = await activeInactiveApi(postData);
      toast.success(res?.data?.data);
      handleTableList();
      setSelectedActionItems([]);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div>
      <PageTitle
        activeMenu="Workout List"
        motherMenu="workout"
        isButton={true}
        action={setAddWorkout}
      />
      <div className="pb-2 d-flex justify-content-between">
        <FilterSearch
          Type="off"
          tabs={true}
          FilterOption={workoutFilterLabels}
          setFilterType={setFilterTab}
          filterType={filterTab}
        />
        {selectedActionItems?.length > 0 && (
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleActionWorkout(selectedActionItems)}
            >
              {filterTab === "active" ? "Inactive" : "Active"}
            </button>
          </div>
        )}
      </div>
      <Col>
        {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width50">
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkbox1_exam_all"
                          onChange={handleSelectAll}
                          checked={
                            selectedActionItems.length === tableData.count
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox1_exam_all"
                        ></label>
                      </div>
                    </th>
                    <th>
                      <strong>Image</strong>
                    </th>
                    <th>
                      <strong>Workout NAME</strong>
                    </th>
                    <th>
                      <strong>Updated on</strong>
                    </th>
                    <th className="text-center">
                      <strong>Exercise count</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.workouts?.map((workout) => (
                    <tr key={workout._id}>
                      <td>
                        <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customCheckBox${workout._id}`}
                            onChange={() => handleCheckboxChange(workout._id)}
                            checked={selectedActionItems.includes(workout._id)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`customCheckBox${workout._id}`}
                          ></label>
                        </div>
                      </td>
                      <td>
                        <S3Image
                          imageKey={workout?.image}
                          type={"Profiles"}
                          fileType={"profile-image"}
                          style={"profile-photo"}
                        />
                      </td>
                      <td>
                        <span className="w-space-no">{workout.title}</span>
                      </td>
                      <td>{moment(workout?.updatedAt).format("LL")}</td>
                      <td className="text-center">
                        {workout?.routine?.length}
                      </td>
                      <td>
                        <div className="d-flex">
                          <div
                            className="btn btn-primary shadow btn-xs sharp mr-1 view-list-icon"
                            onClick={() =>
                              props.history.push({
                                pathname: "workout-exercises",
                                state: {
                                  workout: workout,
                                  pageName: "Exercises List",
                                },
                              })
                            }
                          >
                            <i className="fa fa-eye"></i>
                          </div>
                          <div
                            className="btn btn-warning shadow btn-xs sharp view-list-icon"
                            onClick={() => handleActionWorkout([workout?._id])}
                          >
                            {filterTab === "active" ? (
                              <FontAwesomeIcon
                                icon={faToggleOn}
                                className="icon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faToggleOff}
                                className="icon"
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {tableData?.count === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {addWorkout && (
        <AddNameModal
          title={"Add New Workout"}
          show={addWorkout}
          onHide={() => setAddWorkout(false)}
          buttonTitle={"Add"}
          api={addWorkoutApi}
          refresh={handleTableList}
          inputName={"Workout name"}
        />
      )}
    </div>
  );
}
