import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import AWS from "aws-sdk";

import { editUserApi } from "../components/APIs/Api";

const EditUserModal = ({ show, onHide, selectedUserData }) => {
  const s3 = new AWS.S3();

  const [name, setName] = useState(selectedUserData?.name);
  const [image, setImage] = useState();
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);

  let selectedImage = selectedUserData?.image;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    console.log(file);

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      setNameError("Name cannot be empty");
      return;
    }
    setLoader(true);
    try {
      const imageData = await handleUpload(image);
      const response = await editUserApi(
        name,
        selectedUserData?._id,
        imageData?.Key ? imageData.Key : selectedImage
      );
      onHide();
      toast.success("User Updated successfully");
    } catch (e) {
      toast.error(e?.response?.error?.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {selectedImage && <span>{selectedImage}</span>}
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            />
            {nameError && <span className="text-danger">{nameError}</span>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="button"
          className="btn-sm"
          onClick={onHide}
        >
          Cancel
        </Button>
        <button className="btn-sm btn-primary btn" onClick={handleSave}>
          {loader ? <Spinner animation="border" size="sm" /> : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
