/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import dashbordIcon from "../../../images/dashboard 1.svg";
import userIcon from "../../../images/users.svg";
import categoryIcon from "../../../images/banners.svg";
import notifiIcon from "../../../images/bell 1.svg";
import serviceIcon from "../../../images/report-copy.svg";
import policyIcon from "../../../images/Policies.svg";
import { checkAutoLogin } from "../../../services/AuthService";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      Post = ["posts", "add-post"],
      AppConfiguration = ["app-config", "category-config", "body-part-config"],
      Workout = ["workout-management"],
      Program = ["program-management"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                <i className="flaticon-381-networking"></i>
                {/* <img src={dashbordIcon} /> */}
                <span className="nav-text pl-1">Dashboard</span>
              </Link>
            </li>

            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                {/* <i className="flaticon-user"></i> */}
                <i class="fa fa-users"></i>
                <span className="nav-text pl-1">User Management</span>
              </Link>
            </li>

            <li
              className={`${
                AppConfiguration.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link className="ai-icon " to="app-config">
                <i className="flaticon-381-television"></i>
                {/* <img src={articalIcon}></img> */}
                <span className="nav-text pl-1">App Config</span>
              </Link>
              {/* <ul>
                <li>
                  <Link
                    className={`${
                      path === "category-config" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/category-config"
                  >
                    Category Config
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "body-part-config" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/body-part-config"
                  >
                    Body Part Config
                  </Link>
                </li>
              </ul> */}
            </li>

            <li className={`${Post.includes(path) ? "mm-active" : ""}`}>
              <Link to="posts" className="ai-icon">
                <i class="fa fa-plus-square"></i>
                {/* <i class="fa fa-medal"></i> */}
                {/* <img src={userIcon} /> */}
                <span className="nav-text pl-2">Exercise List</span>
              </Link>
            </li>
            <li className={`${Workout.includes(path) ? "mm-active" : ""}`}>
              <Link to="workout-management" className="ai-icon">
                <i class="flaticon-381-app"></i>
                {/* <i class="fa fa-medal"></i> */}
                {/* <img src={userIcon} /> */}
                <span className="nav-text pl-2">Workout</span>
              </Link>
            </li>
            <li className={`${Program.includes(path) ? "mm-active" : ""}`}>
              <Link to="program-management" className="ai-icon">
                <i class="flaticon-381-archive"></i>
                {/* <i class="fa fa-medal"></i> */}
                {/* <img src={userIcon} /> */}
                <span className="nav-text pl-2">Program</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
