import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { getPostsApi, workoutManagementListApi } from "../components/APIs/Api";

const AddProgramWorkout = ({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  componentId,
  selectedItemData,
  successMessage,
}) => {
  const [schedule, setSchedule] = useState({
    title: selectedItemData?.title,
    workout: selectedItemData?.workout?._id,
  });
  const [workouts, setWorkouts] = useState([]);
  const [nameError, setNameError] = useState("");
  const [dayError, setDayError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchWorkouts = async () => {
    setIsLoading(true);
    try {
      const response = await workoutManagementListApi("active");
      setWorkouts(response?.data?.data?.workouts);
      console.log(response);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchWorkouts();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newSchedule = { ...schedule };
    newSchedule[name] = value;
    setSchedule(newSchedule);
  };

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (schedule?.title?.trim().length === 0) {
      setDayError("Day is required");
      return;
    }
    if (schedule?.workout?.trim().length === 0) {
      setNameError("Workout is required");
      return;
    }
    setLoader(true);

    try {
      let postData;
      postData = {
        programId: componentId,
        workout: schedule.workout,
        title: schedule.title,
      };
      const res = await api(postData);
      setLoader(false);
      refresh();
      onHide(false);
      toast.success(successMessage);
    } catch (e) {
      setLoader(false);
      console.log(e?.response);
      toast.error(e?.response?.data?.message);
    }
  }

  return (
    <Modal className="" show={show} onHide={onHide} centered>
      <div className=" p-3">
        <h3 className="text-center">{title}</h3>
        <form onSubmit={handleAddFormSubmit} className="scroll-div p-3">
          <div className="position-relative">
            <label className="text-black"> Day</label>
            <input
              type="text"
              name="title"
              placeholder="Enter Day"
              className="form-control "
              value={schedule.title}
              onChange={(event) => {
                handleInputChange(event);
                setDayError("");
              }}
            />
            <span className="text-danger">{dayError}</span>
          </div>
          <div className="form-group">
            <label className="text-black">Workout </label>
            <select
              name="workout"
              className="form-control"
              required
              value={schedule.workout}
              onChange={(event) => {
                handleInputChange(event);
                setNameError("");
              }}
            >
              <option value="">Select a workout</option>
              {workouts.map((workout) => (
                <option key={workout._id} value={workout._id}>
                  {workout.title}
                </option>
              ))}
              {workouts.length == 0 && <option>No workout </option>}
            </select>

            <span className="text-danger">{nameError}</span>
          </div>

          {/* <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-sm pr-0 add-input-button"
              onClick={handleAddDay}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
            </button>
          </div> */}
          <div className="d-flex justify-content-end">
            <button className="btn btn-sm btn-primary w-100">
              {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddProgramWorkout;
