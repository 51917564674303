import React, { useState } from "react";
import Switch from "react-switch";
import AddConfig from "../../modal/AddConfig";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
export default function BodyPartConfig() {
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const data = [
    { name: "Leg", date: "April 3 2024", videoLiked: "0" },
    { name: "Chest", date: "April 4 2024", videoLiked: "0" },
    { name: "Back", date: "April 5 2024", videoLiked: "0" },
    { name: "Shoulder", date: "April 7 2024", videoLiked: "0" },
  ];
  return (
    <div>
      <PageTitle
        activeMenu="Body Part Config"
        motherMenu="List"
        isButton={true}
        action={setAddCategoryModal}
      />
      <Col className="pr-0">
        {/* <div className="mt-4 d-flex justify-content-between align-items-center">
            <div
              className="form-group "
              style={{ position: "relative", width: "40%" }}
            >
              <input
                type="text"
                style={{ borderRadius: "0.5rem" }}
                className="form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div> */}
        {/* {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : ( */}
        <Card>
          <Card.Body>
            <Table>
              <thead style={{ color: "black", textAlign: "center" }}>
                <tr>
                  <th>
                    <strong>S.NO</strong>
                  </th>
                  <th>
                    <strong>Body Part</strong>
                  </th>
                  <th>
                    <strong>Created On</strong>
                  </th>
                  <th>
                    <strong>video Liked</strong>
                  </th>

                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {data?.map((item, i) => (
                  <tr key={item.id} className={i % 2 === 0 ? "even-row" : ""}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>{item.videoLiked}</td>

                    <td>
                      <label className="m-0">
                        <Switch
                          //   onChange={() => blockUser(item._id)}
                          checked={!item?.isBlocked}
                        />
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* {usersList?.data?.data?.users?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )} */}
            {/* {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <FilterSearch
                          FilterOption={limitOptions}
                          setFilterType={setItemsPerPage}
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
          </Card.Body>
        </Card>
        {/* )} */}
      </Col>
      {addCategoryModal && (
        <AddConfig
          title={"Add Body Part"}
          buttonTitle={"Add"}
          show={addCategoryModal}
          onHide={() => setAddCategoryModal(false)}
        />
      )}
    </div>
  );
}
