import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({
  pageHeading,
  motherMenu,
  activeMenu,
  isButton,
  action2,
  action,
}) => {
  let path = window.location.pathname.split("/");

  return (
    <div className="page-titles d-flex align-items-start justify-content-between">
      <div>
        <h4>
          {activeMenu}
          {pageHeading}
        </h4>
        <ol className="breadcrumb d-flex align-items-start">
          <li className="breadcrumb-item">
            <Link to="#">{motherMenu}</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="#">{activeMenu}</Link>
          </li>
        </ol>
      </div>
      {isButton ? (
        <div className="">
          <button className="btn btn-sm bTn" onClick={() => action(true)}>
            Add New
          </button>
        </div>
      ) : action2?.length > 1 ? (
        <div className="">
          <Link to="/add-post">
            <button className="btn btn-sm bTn">Add New</button>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default PageTitle;
