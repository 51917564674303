import React, { useState } from "react";
import "./ReelCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import S3Image from "../components/S3Image";
import {
  faEllipsisVertical,
  faEye,
  faEdit,
  faToggleOn,
  faToggleOff,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import ShowPostsModal from "../modal/ShowPostsModal";
import toast from "react-hot-toast";
import { deletePostApi, actionPostApi } from "../components/APIs/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ReelCard = ({ item, refresh, setLoader, location }) => {
  const history = useHistory();
  const [showPostModal, setShowPostModal] = useState(false);
  const fileType = item?.media?.split(".").pop();

  async function blockUser(id) {
    setLoader(true);
    try {
      const res = await actionPostApi(id);
      toast.success(res?.data?.data);
      refresh();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function handleDeletePost(id) {
    setLoader(true);
    try {
      const res = await deletePostApi(id);
      refresh();
      toast.success("Post deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error.response);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div className="reel-card">
      <div onClick={() => setShowPostModal(true)}>
        <S3Image
          imageKey={item.thumbnail ? item?.thumbnail : item?.media}
          type={"reel-img"}
          fileType={fileType}
        />
      </div>
      <div className="option-icon">
        <Dropdown>
          <Dropdown.Toggle
            className=""
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} className="icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!item?.isDeleted && (
              <Dropdown.Item
                onClick={() =>
                  history.push({
                    pathname: "/add-post",
                    state: item,
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} className="icon" />
                View/Edit
              </Dropdown.Item>
            )}
            {!item?.isDeleted && (
              <>
                {item?.isActive ? (
                  <Dropdown.Item onClick={() => blockUser(item._id)}>
                    <FontAwesomeIcon icon={faToggleOff} className="icon" />
                    InActive
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => blockUser(item._id)}>
                    <FontAwesomeIcon icon={faToggleOn} className="icon" />
                    Active
                  </Dropdown.Item>
                )}
                {!item?.isDeleted && (
                  <Dropdown.Item onClick={() => handleDeletePost(item?._id)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="icon" />
                    Delete
                  </Dropdown.Item>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="user-info">
        <p className="caption">
          {item?.name?.length > 50
            ? item?.name?.slice(0, 45) + "..."
            : item?.name}
        </p>
      </div>
      {showPostModal && (
        <ShowPostsModal
          caption={item?.name}
          show={showPostModal}
          onHide={() => setShowPostModal(false)}
          url={item?.media}
          type={fileType}
        />
      )}
    </div>
  );
};

export default ReelCard;
