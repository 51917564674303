import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import S3Image from "../components/S3Image";
import BackIcon from "../../images/icons8-left-50.png";

export default function ShowPostsModal({ type, url, caption, show, onHide }) {
  return (
    <Modal show={show} className="reel-modal">
      {/* <div> */}
      <div className="back-btn-reel">
        <button type="button" className="" onClick={onHide}>
          {"<"}
        </button>
      </div>
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="reel-show-card ">
          <S3Image imageKey={url} fileType={type} />
          <div className="user-info">
            <p className="caption">
              {caption?.length > 70 ? caption?.slice(0, 60) + "..." : caption}
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}
