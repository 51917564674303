import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import Switch from "react-switch";
import Lottie from "lottie-react";
import animationData from "../../../images/loding.json";
import {
  addSubComponentApi,
  subComponentToggleApi,
} from "../../components/APIs/Api";
import { Dropdown, Card, Table, Badge, Col, Spinner } from "react-bootstrap";
import AddConfig from "../../modal/AddConfig";
import toast from "react-hot-toast";
import { configFilterLabels } from "../../components/Variable";
import FilterSearch from "../../components/FilterSearch";
import {
  getSubComponentApi,
  deleteSubComponentApi,
  editSubComponentApi,
} from "../../components/APIs/Api";
import Pagination from "../../components/Pagination";
import moment from "moment";
import S3Image from "../../components/S3Image";

export default function CategoryConfig(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [editCategory, setEditCategory] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterTab, setFilterTab] = useState("active");
  const [selectedItem, setSelectedItem] = useState();

  const item = props?.location?.state?.item;
  const pageTittle = item?.title;

  async function categoryListData() {
    setLoader(true);
    try {
      const res = await getSubComponentApi(
        itemsPerPage,
        currentPage,
        item?._id,
        filterTab
      );
      setData(res);
      setLoader(false);
    } catch (error) {
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  async function handleDeleteSubComponent(id) {
    setLoader(true);
    try {
      const res = await deleteSubComponentApi(id);
      categoryListData();
      setLoader(false);
      toast.success("Sub Component deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  }

  async function handleToggle(id) {
    setLoader(true);
    try {
      const res = await subComponentToggleApi(id);
      categoryListData();

      toast.success(res?.data?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  const totalItems = data?.data?.data.count;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    categoryListData();
  }, [currentPage, filterTab]);

  return (
    <div>
      <div className="pb-2">
        <button
          className="btn btn-sm back-btn"
          onClick={() => props.history.push("/app-config")}
        >
          Back
        </button>
      </div>
      <PageTitle
        activeMenu={item?.title}
        motherMenu="App Config"
        isButton={true}
        action={setAddCategoryModal}
      />
      <div className="pb-2 d-flex justify-content-start">
        <FilterSearch
          Type="off"
          tabs={true}
          FilterOption={configFilterLabels}
          setFilterType={setFilterTab}
          filterType={filterTab}
        />
      </div>
      <Col className="pr-0">
        {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table>
                <thead style={{ color: "black", textAlign: "center" }}>
                  <tr>
                    <th>
                      <strong>S.NO</strong>
                    </th>
                    {pageTittle === "Body Part" && (
                      <th>
                        <strong>Image</strong>
                      </th>
                    )}
                    <th>
                      <strong>{item?.title}</strong>
                    </th>
                    <th>
                      <strong>Created On</strong>
                    </th>
                    <th>
                      <strong>Exercises Linked</strong>
                    </th>

                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {data?.data?.data?.subComponents?.map((item, i) => (
                    <tr key={item.id} className={i % 2 === 0 ? "even-row" : ""}>
                      <td>{i + 1}</td>
                      {pageTittle == "Body Part" && (
                        <td>
                          <S3Image
                            imageKey={item?.image}
                            type={"Profiles"}
                            fileType={"profile-image"}
                            style={"profile-photo"}
                          />
                        </td>
                      )}
                      <td>
                        {item?.title?.length > 30
                          ? item?.title?.slice(0, 25) + ".."
                          : item?.title}
                      </td>
                      <td>{moment(item.createdAt).format("ll")}</td>
                      <td>{item.videoLiked || "0"}</td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          {!item?.isDeleted && (
                            <Dropdown.Menu>
                              {item?.isActive ? (
                                <Dropdown.Item
                                  onClick={() => handleToggle(item?._id)}
                                >
                                  Inactive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => handleToggle(item?._id)}
                                >
                                  Active
                                </Dropdown.Item>
                              )}

                              <Dropdown.Item
                                onClick={() =>
                                  handleDeleteSubComponent(item?._id)
                                }
                              >
                                Delete
                              </Dropdown.Item>
                              {!item?.isDeleted && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setEditCategory(true);
                                    setSelectedItem(item);
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {data?.data?.data?.subComponents?.length === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {data?.data?.data?.subComponents?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Page {currentPage * itemsPerPage + 1} to{" "}
                      {totalItems > (currentPage + 1) * itemsPerPage
                        ? (currentPage + 1) * itemsPerPage
                        : totalItems}{" "}
                      of {totalItems} entries
                    </div>

                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      {/* <FilterSearch
                      FilterOption={limitOptions}
                      setFilterType={setItemsPerPage}
                      limitValue={itemsPerPage}
                    /> */}
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {addCategoryModal && (
        <AddConfig
          title={`Add ${item?.title}`}
          buttonTitle={"Add"}
          show={addCategoryModal}
          onHide={() => setAddCategoryModal(false)}
          api={addSubComponentApi}
          componentId={item?._id}
          refresh={categoryListData}
        />
      )}
      {editCategory && (
        <AddConfig
          title={`Edit ${item?.title}`}
          buttonTitle={"Save"}
          show={editCategory}
          onHide={() => setEditCategory(false)}
          api={editSubComponentApi}
          componentId={item?._id}
          refresh={categoryListData}
          selectedItem={selectedItem}
        />
      )}
    </div>
  );
}
