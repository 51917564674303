import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const deshBoardApiURL = "https://api.baraatco.com/api/v1/admin/home";
const userListApiUrl = "https://api.baraatco.com/api/v1/admin/user";
const UserDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const servicesProviderListUrl = "https://api.baraatco.com/api/v1/getVendor";
const ServicesProviderDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const CategoryListApiUrl = "https://api.baraatco.com/api/v1/category";
const BlockUserUrl = "https://api.baraatco.com/api/v1/admin/user";
const AddCategoryUrl = "https://api.baraatco.com/api/v1/category";
const AddServiceUrl = "https://api.baraatco.com/api/v1/admin/vendor";
const updateCategoryUrl = "https://api.baraatco.com/api/v1/category";
const updateServiceUrl = "https://api.baraatco.com/api/v1/admin/vendor";
const BlockServiceUrl = "https://api.baraatco.com/api/v1/admin/user";

// const role="";
const token = localStorage.getItem("bermudaToken");

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/auth/dashboard`, {});
  return response;
}
export async function userManagementList(itemsPerPage, currentPage, search) {
  const response = await axiosInstance.get(
    `admin/usersList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}

export async function appConfigApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `admin/workout/components?limit=${"10"}&page=${"0"}`,
    {}
  );
  return response;
}

export async function getCategoryBodyPartApi(type) {
  const response = await axiosInstance.get(
    `admin/workout/filterSubComponent?type=${type}`,

    {}
  );
  return response;
}
export async function getSubComponentApi(
  itemsPerPage,
  currentPage,
  componentId,
  filterTab
) {
  const response = await axiosInstance.get(
    `admin/workout/subComponent?limit=${itemsPerPage}&page=${currentPage}&componentId=${componentId}&type=${filterTab}`,
    {}
  );
  return response;
}

export async function getPostsApi(type) {
  const response = await axiosInstance.get(
    `admin/workout/exerciseList?type=${type}&limit=${"0"}&page=${"100"}`,

    {}
  );
  return response;
}

export async function complaintListApi(itemsPerPage, currentPage, type) {
  const response = await axiosInstance.get(
    `admin/getComplaints?limit=${itemsPerPage}&page=${currentPage}&type=${type}`,
    {}
  );
  return response;
}

export async function viewUserDetails(id) {
  const response = await axiosInstance.get(`${UserDetailsUrl}/${id}`, {});
  return response;
}

export async function servicesProviderListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `getVendor?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function servicesProviderDetailsApi(id) {
  const response = await axios.get(`${ServicesProviderDetailsUrl}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });
  return response;
}

export async function categoryListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `category?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function workoutManagementListApi(type) {
  const response = await axiosInstance.get(
    `admin/workout/workouts?limit=${"10"}&page=${"0"}&type=${type}`,
    {}
  );
  return response;
}

export async function getWorkoutExercisesApi(id) {
  const response = await axiosInstance.get(
    `admin/workout/workoutExerciseList?page=${0}&limit=${100}&workoutId=${id}`,
    {}
  );
  return response;
}

export async function programListApi(type) {
  const response = await axiosInstance.get(
    `admin/workout/programs?limit=${"10"}&page=${"0"}&type=${type}`,
    {}
  );
  return response;
}
export async function programWorkoutApi(id) {
  const response = await axiosInstance.get(
    `admin/workout/programWorkoutsList?limit=${"10"}&page=${"0"}&programId=${id}`,
    {}
  );
  return response;
}
// ================putAPI==========================

export async function EditPostApi(data) {
  const response = await axiosInstance.put("admin/workout/exercise", data, {});
  return response;
}

export async function activeInactiveApi(data) {
  const response = await axiosInstance.put(
    "admin/workout/toggleWorkoutProgram",
    data,
    {}
  );
  return response;
}
// const data={}
export async function blockUserApi(id) {
  const data = { userId: id };
  const response = await axiosInstance.put("admin/user", data);
  return response;
}
export async function actionPostApi(id) {
  const data = { exerciseId: id };
  const response = await axiosInstance.put(
    "admin/workout/toggleExercise",
    data
  );
  return response;
}

export async function subComponentToggleApi(id) {
  const data = { subComponentId: id };
  const response = await axiosInstance.put(
    "admin/workout/toggleSubComponent",
    data
  );
  return response;
}

export async function onChangeAdminPasswordApi(newPassword, oldPassword) {
  const data = { newPassword, oldPassword };
  const response = await axiosInstance.put(`admin/chnagePassword`, data);
  return response;
}

export async function updateComplaintApi(complaintId, status) {
  const postData = { complaintId, status };

  // console.log(formData,"formData i api")
  // const data={status:status}
  const response = await axiosInstance.put(`admin/complaintAction`, postData);
  return response;
}

export async function editServiceApi(EditFormData) {
  const formData = new FormData();
  formData.append("file", EditFormData.file);
  formData.append("name", EditFormData.name);
  formData.append("email", EditFormData.email);
  formData.append("category", EditFormData.category);
  formData.append("number", EditFormData.phone);
  formData.append("password", EditFormData.password);
  formData.append("userId", EditFormData.userId);
  formData.append("instagram", EditFormData.instagramId);
  formData.append("website", EditFormData.website);
  formData.append("description", EditFormData.description);
  formData.append("countryCode", EditFormData.countryCode);

  console.log(EditFormData, "formData i api");
  // const data={status:status}
  const response = await axiosInstance.put(updateServiceUrl, formData, {});
  return response;
}
export async function editSubAdminApi(data) {
  const response = await axiosInstance.put("admin/editProfileSubAdmin", data);
  return response;
}

export async function policyApi(privacyPolicy) {
  const data = { privacyPolicy };
  const response = await axiosInstance.post(`admin/createPrivacyPolicy`, data);
  return response;
}

export async function termApi(termsAndConditions) {
  const data = { termsAndConditions };
  const response = await axiosInstance.post(
    `admin/createTermsAndConditions`,
    data
  );
  return response;
}
export async function aboutApi(about) {
  const data = { aboutUs: about };
  const response = await axiosInstance.post(`admin/aboutus`, data);
  return response;
}

export async function editWorkoutExercisesApi(data) {
  const response = await axiosInstance.put(
    "admin/workout/editWorkoutExercise",
    data,
    {}
  );
  return response;
}
export async function editProgramWorkoutApi(data) {
  const response = await axiosInstance.put(
    "admin/workout/editProgramWorkout",
    data,
    {}
  );
  return response;
}
export async function editSubComponentApi(data) {
  const response = await axiosInstance.put(
    "admin/workout/subComponent",
    data,
    {}
  );
  return response;
}
export async function editUserApi(name, id, image) {
  const data = { name: name, userId: id, image: image };
  const response = await axiosInstance.put("admin/userDetails", data, {});
  return response;
}

// ======================post=============================

//===============Add Post =================

export async function addPostApi(data) {
  const response = await axiosInstance.post("admin/workout/exercise", data, {});
  return response;
}

export async function addCategoryApi(name, file) {
  console.log(name, file);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("category", name);
  console.log(formData, "catdata i api");
  // const data={AddCategoryData};
  const response = await axiosInstance.post(AddCategoryUrl, formData, {});
  return response;
}

export async function addServiceApi(data) {
  const response = await axiosInstance.post(AddServiceUrl, data, {});
  return response;
}

export async function addConfigApi(title) {
  const data = { title: title };
  const response = await axiosInstance.post(
    "admin/workout/components",
    data,
    {}
  );
  return response;
}

export async function addSubComponentApi(postData) {
  // const data = { title: title, componentId: id };
  const response = await axiosInstance.post(
    "admin/workout/subComponent",
    postData,
    {}
  );
  return response;
}

export async function addWorkoutApi(postData) {
  const data = { title: postData.name, image: postData.image };
  const response = await axiosInstance.post("admin/workout/workouts", data, {});
  return response;
}
export async function addProgramApi(postData) {
  const data = { title: postData.name, media: postData.image };
  const response = await axiosInstance.post("admin/workout/programs", data, {});
  return response;
}
export async function addWorkoutExercisesApi(data) {
  const response = await axiosInstance.post(
    "admin/workout/exercise/addToWorkout",
    data,
    {}
  );
  return response;
}

export async function addProgramWorkoutApi(data) {
  const response = await axiosInstance.post(
    "admin/workout/addWorkoutToProgram",
    data,
    {}
  );
  return response;
}

export async function addMultiVendorApi(formData) {
  // const data = { vendors: formData };
  const response = await axiosInstance.post(
    "admin/addbulkVendor",
    formData,
    {}
  );
  return response;
}
export async function addSubAdminApi(data) {
  const response = await axiosInstance.post("admin/createSubAdmins", data);
  return response;
}

export async function downloadReportApi(data) {
  const postData = {
    firstName: data.UserDetails.FirstName,
    lastName: data.UserDetails.LastName,
    phoneNumber: data.UserDetails.PhoneNumber,
    email: data.UserDetails.Email,
    search: "",
    startDate: "",
    endDate: "",
    disabilityInformation: {
      typeOfDisability: data.Disability.TypeOfDisability,
      severity: data.Disability.TypeOfDisability,
      onsetOfDisability: data.Disability.OnsetOfDisability,
    },
    education: {
      highestEductionLevel: data?.EducationOccupation.HighestEducationLevel,
      employementStatus: data.EducationOccupation.EmploymentStatus,
      occupationalNeeds: data.EducationOccupation.EducationOccupationalNeeds,
    },
    healthInformation: {
      medicalTreatment: data.HealthInformation.OngoingMedicalTreatment,
      insuranceProvider: data.HealthInformation.HealthInsuranceProvider,
    },
    socioeconomicInformation: {
      houseHoldCompositon: data.SocioeconomicInformation.HouseholdComposition,
      livingConditions: data.SocioeconomicInformation.LivingConditions,
      houseHoldIncome: data.SocioeconomicInformation.HouseholdIncome,
    },
    supportAndAssistance: {
      assistanceReceived: data.SupportAssistance.AssistanceReceived,
      assistiveDevices: data.SupportAssistance.AssistiveDevices,
      unmetAssistanceNeeds: data.SupportAssistance.UnmetAssistanceNeeds,
      programParticipation: data.SupportAssistance.ProgramParticipation,
    },
    additionalInformation: {
      supportRelationship: data.AdditionalInformation.SupportRelationship,
      relationshipToContact: data.AdditionalInformation.RelationshipToContact,
      supportContactNumber: data.AdditionalInformation.SupportContactNumber,
      supportEmail: data.AdditionalInformation.SupportEmail,
      supportPerson: data.AdditionalInformation.SupportPerson,
      emergencyContactNumber: data.AdditionalInformation.EmergencyContactNumber,
      emergencyContactName: data.AdditionalInformation.EmergencyContactName,
      emailAddress: data.AdditionalInformation.EmailAddress,
    },
  };

  const response = await axiosInstance.put("admin/exportData", postData);
  return response;
}

//===============Delete============================================================
export async function deleteCategoryApi(id) {
  const response = await axiosInstance.delete(`category/${id}`);
  return response;
}
export async function deleteUserApi(id) {
  const response = await axiosInstance.delete(`admin/user?userId=${id}`);
  return response;
}
export async function deleteAdminApi(id) {
  const data = { id: id };
  const response = await axiosInstance.delete(`admin/deleteSubAdmin`, data);
  return response;
}

//===============Delete============================================================

export async function deletePostApi(id) {
  const response = await axiosInstance.delete(
    `admin/workout/exercise?exerciseId=${id}`
  );
  return response;
}

export async function deleteAppConfigApi(id) {
  const response = await axiosInstance.delete(
    `admin/workout/components?componentId=${id}`
  );
  return response;
}
export async function deleteSubComponentApi(id) {
  const response = await axiosInstance.delete(
    `admin/workout/subComponent?subComponentId=${id}`
  );
  return response;
}
