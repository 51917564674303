import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/aaaaaa.png";
import { Spinner } from "react-bootstrap";
import emptyImage from "../../images/defaulImg.jpg";

const S3Image = ({ imageKey, type, onRemove, fileType, style }) => {
  const [imageData, setImageData] = useState(null);

  console.log(imageKey, "updateImage");
  const onImageError = (e) => {
    e.target.src = type === "Profiles" ? emptyImage : defaultImage;
  };
  useEffect(() => {
    const fetchImageData = async () => {
      if (!imageKey) {
        console.log("Empty image key");
        return;
      }
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData && !type === "Profiles") {
    return (
      <div>
        <div className="video-player d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }

  return (
    <>
      {type === "Profiles" ? (
        <img
          height={45}
          width={45}
          src={`data:image/svg;base64,${imageData}`}
          alt="image"
          className={style}
          onError={onImageError}
        />
      ) : (
        <div>
          {type === "edit-img" ? (
            <div className="image-picker position-relative">
              {fileType === "mp4" ? (
                <>
                  <video src={`data:image/svg;base64,${imageData}`} />
                  <button className="remove-btn" onClick={onRemove}>
                    X
                  </button>
                </>
              ) : (
                <>
                  <img
                    src={`data:image/svg;base64,${imageData}`}
                    alt="Selected"
                    className="preview-image"
                  />
                  <button className="remove-btn" onClick={onRemove}>
                    X
                  </button>
                </>
              )}
            </div>
          ) : (
            <div>
              <div className="reel-show-card">
                {fileType === "mp4" ? (
                  <video
                    src={`data:video/mp4;base64,${imageData}`}
                    autoPlay
                    controls
                    muted
                  />
                ) : (
                  <img
                    className="video-player"
                    src={`data:image/svg;base64,${imageData}`}
                  />
                )}
              </div>
              {type === "reel-img" && (
                <img
                  className="video-player"
                  src={`data:image/svg;base64,${imageData}`}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default S3Image;
