// import React, { useRef } from "react";
// // import './ImagePicker.css';

// const ImagePicker = ({ selectedImage, setSelectedImage }) => {
//   const inputRef = useRef(null);

//   const handleClick = () => {
//     inputRef.current.click();
//   };
//   //   const handleImageChange = (event) => {
//   //     selectedImageShow;
//   //   };

//   return (
//     <div className="image-picker">
//       {/* <label className="text-center w-100" onClick={handleClick}>
//         Choose Image
//       </label> */}
//       <input
//         type="file"
//         id="image-picker"
//         accept="image/*,video/*"
//         onChange={(e) => setSelectedImage(e.target.files[0])}
//         ref={inputRef}
//         style={{ display: "none" }}
//       />
//       {!selectedImage && (
//         <i
//           class="fa fa-plus-square"
//           style={{ fontSize: "50px", cursor: "pointer" }}
//           onClick={handleClick}
//         ></i>
//       )}
//       {selectedImage && (
//         <img
//           src={URL.createObjectURL(selectedImage)}
//           alt="Selected"
//           className="preview-image"
//         />
//       )}
//     </div>
//   );
// };

// export default ImagePicker;

import React, { useRef } from "react";

const ImagePicker = ({
  selectedImage,
  setSelectedImage,
  setThumbnail,
  thumbnail,
  onRemove,
}) => {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      if (file.type.startsWith("image/")) {
        setSelectedImage(file);
      } else if (file.type.startsWith("video/")) {
        setSelectedImage(file);
        generateThumbnail(file);
      }
    }
    e.target.value = null;
  };

  const generateThumbnail = (file) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = url;

    video.onloadeddata = () => {
      video.currentTime = video.duration / 2;
    };

    video.onseeked = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        const imgFile = new File([blob], `${file.name}-thumbnail.png`, {
          type: "image/png",
        });
        setThumbnail(imgFile);
      }, "image/png");
      URL.revokeObjectURL(url);
    };
  };

  return (
    <div className="image-picker position-relative">
      <input
        type="file"
        id="image-picker"
        accept="image/*,video/*"
        onChange={handleImageChange}
        ref={inputRef}
        style={{ display: "none" }}
      />
      {!selectedImage && !thumbnail && (
        <i
          className="fa fa-plus-square"
          style={{ fontSize: "50px", cursor: "pointer" }}
          onClick={handleClick}
        ></i>
      )}
      {!thumbnail && selectedImage && (
        <>
          <img
            src={URL.createObjectURL(selectedImage)}
            alt="Selected"
            className="preview-image"
          />
          <button className="remove-btn" onClick={onRemove}>
            X
          </button>
        </>
      )}
      {thumbnail && (
        <>
          <img
            src={URL.createObjectURL(thumbnail)}
            alt="Selected"
            className="preview-image"
          />
          <button className="remove-btn" onClick={onRemove}>
            X
          </button>
        </>
      )}
    </div>
  );
};

export default ImagePicker;
