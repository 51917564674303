import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelectDropdown = ({ selected, setSelected, options }) => {
  console.log(selected);
  return (
    <div style={{ minHeight: "200px" }}>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
      />
    </div>
  );
};

export default MultiSelectDropdown;
