import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import AddExercisesModal from "../modal/AddExercisesModal";
import {
  getWorkoutExercisesApi,
  addWorkoutExercisesApi,
  programWorkoutApi,
  editWorkoutExercisesApi,
} from "../components/APIs/Api";
import toast from "react-hot-toast";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import moment from "moment";

export default function WorkoutsExercisesList(props) {
  const workoutItem = props.location.state;
  const [addExercises, setAddExercises] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState();
  const [tableData, setTableData] = useState([]);
  const [editExercises, setEditExercises] = useState(false);

  async function handleTableList() {
    setLoader(true);
    try {
      const res = await getWorkoutExercisesApi(workoutItem?.workout?._id);
      setTableData(res?.data?.data);

      setLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleTableList();
  }, []);

  return (
    <div>
      <PageTitle
        activeMenu={workoutItem?.pageName}
        motherMenu={workoutItem?.workout?.title}
        isButton={true}
        action={setAddExercises}
      />

      <Col>
        {loader ? (
          <div>
            <Lottie
              style={{
                height: "200px",
                marginTop: "45px",
                padding: "30px",
              }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Exercise NAME</strong>
                    </th>
                    <th>
                      <strong>Updated on</strong>
                    </th>
                    <th>
                      <strong>Sets</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((exercise) => (
                    <tr key={exercise._id}>
                      <td>
                        <span className="w-space-no">
                          {exercise.exercise.name}
                        </span>
                      </td>
                      <td>
                        {moment(exercise?.exercise?.updatedAt).format("LL")}
                      </td>
                      <td>{exercise.sets}</td>
                      <td>
                        <div className="d-flex">
                          <div
                            className="btn btn-primary shadow btn-xs sharp ml-3 view-list-icon"
                            onClick={() => {
                              setEditExercises(true);
                              setSelectedItemData(exercise);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {tableData?.length === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {addExercises && (
        <AddExercisesModal
          title={"Add New Exercise"}
          show={addExercises}
          onHide={() => setAddExercises(false)}
          buttonTitle={"Add"}
          api={addWorkoutExercisesApi}
          refresh={handleTableList}
          componentId={workoutItem?.workout?._id}
          successMessage={"Exercise Added successfully"}
          inputName={"Exercise name"}
        />
      )}
      {editExercises && (
        <AddExercisesModal
          title={"Edit Workout Exercise"}
          show={editExercises}
          onHide={() => setEditExercises(false)}
          buttonTitle={"Save"}
          api={editWorkoutExercisesApi}
          successMessage={"Exercise Update successfully"}
          refresh={handleTableList}
          componentId={workoutItem?.workout?._id}
          selectedItemData={selectedItemData}
          inputName={"Exercise name"}
          workoutExerciseId={selectedItemData?._id}
        />
      )}
    </div>
  );
}
