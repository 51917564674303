export const variable = {
  id: null,
};

export const configFilterLabels = [
  { value: "active", label: "Active", id: 2 },
  { value: "inactive", label: "Inactive", id: 3 },
  { value: "deleted", label: "Deleted", id: 1 },
];
export const workoutFilterLabels = [
  { value: "draft", label: "Draft", id: 1 },
  { value: "active", label: "Active", id: 2 },
  { value: "inactive", label: "Inactive", id: 3 },
];
